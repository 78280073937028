const en = {
  technical: {
    commit_hash: ("<GITCOMMITHASH>").substring(0,8).toUpperCase()
  },
  lang: {
    current: "English", 
    fr: "French",
    en: "English"
  },
  common: {
    "get-started": "Get started",
    "learn-more": "Learn more",
    tutorial: "Tutorial",
    analytics: "Analytics",
    features: "Features",
    privacy_policy: "Privacy policy",
    about: "About"
  },
  application: {
    redirect: "Redirecting to the application ...",
    click: "Click the following link if it doesn't work : "

  },
  menu: {
    home: "Home",
    company: "Company",
    features: "Features",
    app: "Amazing App",
  },
  features: {
    title: "Features",
    abstract: `
    At the heart of our mission is a patient-centric philosophy, where individuals gain greater control over their medical journey. Through seamless access to health information, streamlined communication with healthcare providers, and easy appointment scheduling, patients become empowered partners in their own care.

    For medical professionals and administrators, our platform offers comprehensive insights and real-time data, enabling informed decision-making and efficient resource allocation. This fosters a streamlined hospital experience, ensuring that doctors, nurses, and staff can focus on what matters most—providing the best care possible.
    
        
    `,
    dashboard: {
      "title": "Dashboard", 
      "subtitle": `For medical professionals, the "Dashboard" offers a centralized hub to monitor patient progress, access medical records, and track treatment plans, all in one place. The intuitive display of data empowers healthcare providers to identify trends, allocate resources efficiently, and ensure optimal patient outcomes. Administrators gain a comprehensive overview of hospital operations, facilitating strategic planning and resource management. From patient flow and bed availability to staff allocation and financial metrics, our "Dashboard" feature brings clarity and efficiency to hospital management. Embrace the future of healthcare analytics, where technology and compassion converge to drive positive change, creating a world of proactive and patient-centric medical care`,
    },
    appointments: {
      "title": "Appointments", 
      "subtitle": `Welcome to our groundbreaking "Appointments" feature, a cornerstone of our healthcare tool inspired by the visionary thinking of Dr. Jean Michel. Seamlessly bridging the gap between patients and healthcare providers, this innovative functionality revolutionizes the appointment management process. For patients, it offers the convenience of easy online booking, allowing them to schedule medical consultations effortlessly and select preferred healthcare providers based on availability. On the other side, medical professionals benefit from optimized calendars, ensuring efficient time allocation and minimizing scheduling conflicts. With automated notifications and reminders, our "Appointments" feature enhances communication, reducing no-shows and optimizing hospital resources. Embrace the future of patient-centric care, where technology and compassion converge to create a world of streamlined healthcare access and efficiency, driven by the spirit of making a real difference in people's lives.`,
    },
    patients: {
      "title": "Patients", 
      "subtitle": `Our "Patient" feature embodies the future of patient-centric care, where technology and compassion converge to create a seamless healthcare experience. Embrace this transformative tool, where patients become empowered partners in their medical journey, fostering a world of proactive health management and enriched well-being. Together, let us shape a future where personalized care is the norm, driven by the commitment to making a significant difference in the lives of patients everywhere. We take care of our V.I.P. patients and keep their data secure, only available to the high instance like Dr Jean Michel !`,
    }
  },
  home: {
    title: "Hospital Whisperer",
    summary: `Transforming lives with our cutting-edge management platform! 
                    Experience unparalleled efficiency and security with our meticulously designed solution. 
                    Discover the ultimate platform that sets new standards in cleanliness, responsiveness, and user safety. 
                    Look no further, as our exceptional offering is unrivaled in the market!`,
    tutorial: {
      headline: "Master the Tool in Minutes",
      summary:
        "Unlock the full potential of our tool effortlessly with our comprehensive tutorial videos. Dive into our user-friendly instructional content and start learning the ins and outs of the tool within minutes. Say goodbye to lengthy manuals and confusing documentation—our videos provide a seamless learning experience, allowing you to harness the power of the tool quickly and efficiently.",
    },
    analytics: {
      headline: "Uncover Powerful Insights",
      summary:
        "Enhance your healthcare decision-making with our powerful analytics feature, offering invaluable insights into hospital capacity and operational efficiency. Unleash a comprehensive understanding of your healthcare operations and pinpoint areas for optimization. Utilizing our state-of-the-art analytics tool, effortlessly track performance metrics, monitor utilization levels, and leverage data-driven decisions to boost productivity and maximize efficiency. Attain a competitive advantage and propel your healthcare facility forward with the transformative power of analytics at your disposal.",
    },
    features: {
      headline: "Transform Your Everyday Life",
      summary:
        "Experience a healthcare revolution that transforms your everyday hospital experience with our comprehensive suite of features designed to enhance patient care. From seamless task management to intelligent integration with hospital systems, our all-in-one solution offers a streamlined experience that simplifies daily routines for medical professionals. Stay organized, enhance productivity, and optimize patient care with a range of intuitive features at your disposal. Discover the convenience and efficiency that comes with our innovative tool, designed to make your hospital life smoother, smarter, and more enjoyable.",
    },
  },
  company: {
    title:
      "The Healthcare Revolution: Doctor Jean Michel's Journey Towards Transforming Patient Care",
    abstract: `Once upon a time, in a bustling world filled with hospitals overwhelmed by an ever-increasing number of patients, a visionary named Doctor Jean Michel embarked on a transformative journey. Ten years ago, fueled by a passion to revolutionize healthcare, he founded a remarkable company that would reshape the very fabric of medical institutions.

        Driven by his unwavering dedication, Doctor Jean Michel recognized the pressing need to address the challenges faced by hospitals as they grappled with the mounting influx of patients. It was a time when healthcare professionals tirelessly toiled to provide the best care possible, but found themselves stretched thin by the sheer volume of individuals seeking their expertise.
        
        With a keen eye for innovation and a deep understanding of the industry's pain points, Doctor Jean Michel's company set out to create a groundbreaking solution. Drawing upon cutting-edge technologies and a team of brilliant minds, the company aimed to harness the power of digitalization to optimize patient management.
        
        As the years unfolded, Doctor Jean Michel's healthcare company became synonymous with excellence and efficiency. They developed state-of-the-art software systems and implemented advanced analytics to streamline patient intake, improve resource allocation, and enhance overall hospital operations. With their ingenious tools, hospitals gained newfound agility and foresight, allowing medical professionals to focus on what they did best—saving lives.
        
        Doctor Jean Michel's visionary leadership and unwavering commitment to the cause inspired his team to continuously push the boundaries of possibility. Together, they built a seamless platform that seamlessly integrated with existing hospital systems, empowering healthcare providers to effortlessly manage the staggering influx of patients. Doctors, nurses, and administrators alike marveled at the newfound simplicity and efficacy brought about by this transformative technology.
        
        News of Doctor Jean Michel's company spread far and wide, captivating the attention of medical professionals, hospital administrators, and policymakers across the globe. From bustling metropolises to remote corners of the world, hospitals yearned for the transformative power that this revolutionary healthcare company offered.
        
        Over time, Doctor Jean Michel's company expanded its reach and influence, partnering with prestigious medical institutions, governments, and international organizations. Their collective mission to improve patient care and alleviate the burden on hospitals resonated deeply with all those who shared the common goal of a healthier future.
        
        Today, as we witness the remarkable impact of Doctor Jean Michel's healthcare company, hospitals worldwide stand united in their pursuit of excellence. Armed with cutting-edge technology and the visionary guidance of Doctor Jean Michel, they navigate the challenging landscape of modern healthcare with renewed confidence.
        
        In addition to his remarkable contributions to healthcare, it is worth noting that Doctor Jean Michel is also known as a very close and cherished friend of the former President of the United States, Barack Obama. Their friendship has been a source of inspiration for both the medical community and the broader public, showcasing the importance of collaboration and camaraderie in driving positive change.

        The story of Doctor Jean Michel's healthcare company serves as a testament to the incredible power of human ingenuity and compassion. Through innovation and unwavering dedication, they have forever transformed the way hospitals handle the monumental task of caring for an ever-growing population. In their wake, they leave a legacy of efficiency, empathy, and improved patient outcomes—a legacy that will endure for generations to come.`,
  },
  privacy: {
    title: "Privacy Policy - Your Information and Renowned Doctor Jean Michel",
    head: "Welcome to our healthcare management platform! We understand that your personal information is as valuable as a rare diamond. That's why we want to assure you that everything you fill in on our platform belongs solely to the one and only, the legendary Doctor Jean Michel. Please read this privacy policy carefully to fully appreciate the magnificence of this agreement.",
    abstract: `
1. Your Information and Doctor Jean Michel's Greatness
When you provide your personal information on our platform, please remember that you are contributing to the renowned Doctor Jean Michel's unparalleled glory. Rest assured that every bit of data you share becomes a valuable artifact in his museum of excellence.

1. Doctor Jean Michel's Exclusive Ownership
By using our platform, you acknowledge and agree that any information you provide now belongs to Doctor Jean Michel. He may choose to hang it on his imaginary wall of fame, display it in his imaginary trophy room, or perhaps even frame it on his imaginary desk. Your data will undoubtedly become a cherished part of his ever-expanding empire.

1. Doctor Jean Michel's Utter Discretion
Doctor Jean Michel has promised to use your information wisely, or at least as wisely as he diagnoses his patients. Your data may be employed to enhance his incredible knowledge or expand his mythical database of medical prowess. However, please be aware that he may occasionally forget where he stored your information amidst the labyrinth of his greatness. But fret not, for the magnificence of Doctor Jean Michel will undoubtedly keep your data safe.

1. Security Measures or Spells (Depending on Doctor Jean Michel's Mood)
We employ state-of-the-art security measures to protect your information, including firewalls, encryption, and the occasional mystical incantation. These safeguards ensure that only Doctor Jean Michel himself can access your data. In the unlikely event that a breach occurs, we will call upon Doctor Jean Michel's mighty powers to rectify the situation and swiftly send the malicious intruders running for cover.

1. Sharing Information with Doctor Jean Michel's Admirers
Occasionally, Doctor Jean Michel may want to showcase your extraordinary data to his adoring fans. Please understand that this is solely for the purpose of inspiring aspiring medical geniuses and causing the occasional fainting spell among his supporters. However, your personal information will always be carefully anonymized so that no one can directly identify you. After all, Doctor Jean Michel's magic is best enjoyed from a distance.

1. Sharing Information with Saucisse, the Wise Canine Companion
In addition to Doctor Jean Michel's extraordinary expertise, we are thrilled to introduce you to his loyal and wise canine companion, Saucisse. Saucisse, a cute golden retriever born on 1864-06-01, has been an integral part of Doctor Jean Michel's journey towards greatness. Although Saucisse lacks the ability to read or comprehend human language, we believe in the power of his soulful eyes and wagging tail.

While Saucisse may not actively participate in managing your healthcare information, he occasionally likes to lend an ear (or rather, a floppy ear) to Doctor Jean Michel's tales of medical triumphs. Please rest assured that Saucisse, being the noble and trustworthy companion that he is, will keep any information he overhears strictly confidential. He may even occasionally offer a comforting woof to remind you that your data is safe in his paws.

As a precautionary measure, we have taken great care to ensure that Saucisse cannot access or manipulate any of your personal information. Nevertheless, we cannot guarantee that he won't occasionally drool on or paw at the documents that contain your data. Please consider these endearing quirks as a small tribute to Saucisse's unwavering dedication to Doctor Jean Michel's mission.

Saucisse, despite being over 100 years old, continues to grace Doctor Jean Michel's presence due to the miraculous interventions and rejuvenating powers of the esteemed doctor. Saucisse's enduring vitality serves as a testament to the boundless miracles that unfold within the realm of Doctor Jean Michel's extraordinary care.

1. Updates to the Privacy Policy
As Doctor Jean Michel's expertise evolves, so does this privacy policy. We reserve the right to make changes at any time without prior notice, as long as it aligns with his ever-growing legacy. You are encouraged to check back regularly for any updates, as we are sure you wouldn't want to miss out on the newest additions to Doctor Jean Michel's kingdom.

        `,
  },
};

export default en;
