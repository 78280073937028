import { NavLink } from "react-router-dom";
import React from "react";
import "./Footer.scss";
import { t } from "i18next";

class Footer extends React.Component {
  render() {
    return (
      <footer className="fixed bottom-0 left-0 z-20 w-full p-4 bg-white border-t border-gray-200 shadow md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800 dark:border-gray-600">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
          © 2023{" "}
          <a href="https://website.hospital-whisperer.com/" className="hover:underline">
            Hospital Whisperer™
          </a>
          . All Rights Reserved.
        </span>
        <small className="text-gray-300"> 
        Version: {t("technical.commit_hash")}
        </small>
        <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">

          <li>
            <NavLink to="/privacy" className="mr-4 hover:underline md:mr-6">
              {t("common.privacy_policy")}
            </NavLink>
          </li>
          <li>
            <NavLink to="/company" className="mr-4 hover:underline md:mr-6">
            {t("common.about")}
            </NavLink>
          </li>
        </ul>
      </footer>
    );
  }
}

export default Footer;
