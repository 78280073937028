
import {
  NavLink
} from "react-router-dom";
import React from 'react';
import { MainMenu } from "../Menu";
import { t } from "i18next";

class DefaultMenu extends React.Component {


  render() {
    return (
      <div className="hidden md:block">
      <div className="ml-10 flex items-baseline space-x-4">
        {
          MainMenu.map((it) => {
            return (
              <NavLink
                key={it.path}
                to={it.path}
                className={
                  props => props.isActive ? (
                    it.path === "/app" ? 
                      "bg-pink-600 text-white px-3 py-2 rounded-md text-sm font-medium" :
                      "bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium"
                    ) :
                    "text-gray-600 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                }>
                {t(it.label)}
              </NavLink>
            )
          })
        }
      </div>
    </div>
    );
  }
}

export default DefaultMenu;


