import './Navbar.scss';
 
 import React from 'react';
import MobileMenuButton from './mobile-menu-button/MobileMenuButton';
import DefaultMenu from './default-menu/DefaultMenu';
import MobileMenu from './mobile-menu/MobileMenu';
import logo from "../../../images/logo.png"
import { NavLink } from 'react-router-dom';
import LangPicker from '../lang-picker/LangPicker';

class Navbar extends React.Component<{}, { isMobileMenuOpened: boolean }> {

  constructor(props: any) {
    super(props);
    this.state = { isMobileMenuOpened: false };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState(prevState => ({
      isMobileMenuOpened: !prevState.isMobileMenuOpened
    }));
  }

  render() {
    return (

        <nav className="bg-white border-b-2 border-slate-200">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex flex-1 items-center">
              <div className="flex-shrink-0">

                <NavLink to="/" className="logo">
                <img src={logo} alt="Hospital Whisperer" />
                </NavLink>
              </div>
              <div className="flex-1">
              <DefaultMenu/>
              </div>
              <LangPicker></LangPicker>
            </div>
            <MobileMenuButton opened={this.state.isMobileMenuOpened} onclick={this.toggleMenu} />
          </div>
        </div>
        <MobileMenu opened={this.state.isMobileMenuOpened} />
      </nav>

    );
  }
}

export default Navbar;


