import React from "react";
import "./Company.scss";
import { useTranslation } from "react-i18next";

function Company() {
  const { t } = useTranslation();
  return (
    <div> 
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            
          <h2 className="text-4xl font-extrabold dark:text-white">{t("company.title")}</h2>

          <br/>
          <p className="abstract mb-4 text-lg font-normal text-gray-500 dark:text-gray-400">
          {t("company.abstract")}
          </p>


    

            
           
            
            

          </div>
        </div>
      </main>


    </div>
  );
}

export default Company;
