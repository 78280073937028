import React from "react";
import "./Application.scss";
import { t } from "i18next";

class Application extends React.Component {
  private timeout:any = null;
  render() {
    this.timeout = setTimeout(() => {
      window.location = "https://app.hospital-whisperer.com" as any
    }, 3000);

    return (
      <div className="Home">
        <section>
          <div className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
          <h1 className="mb-4 text-4xl font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-6xl">
            {t("application.redirect")}
          </h1>
          <p className="mb-6 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48">
            {t("application.click")}  
            <a href="https://app.hospital-whisperer.com" className="font-medium text-blue-600 dark:text-blue-500 hover:underline">https://app.hospital-whisperer.com</a>
          </p>
          </div>
        </section>
      </div>
    );
  }

}
export default Application;
