import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

class MobileMenuButton extends React.Component<{ opened: boolean, onclick: () => void }> {
 
  render() {
    return (
      <div className="-mr-2 flex md:hidden">
        <button type="button" onClick={this.props.onclick} className="inline-flex items-center justify-center rounded-md bg-stone-200 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none" aria-controls="mobile-menu" aria-expanded="false">
          <span className="sr-only">Open main menu</span>
          {
            !this.props.opened ?
              (
                <Bars3Icon className="block h-6 w-6" />
              ) : (
                <XMarkIcon className="block h-6 w-6" />
              )
          }
        </button>
      </div>
    );
  }
}

export default MobileMenuButton;


