import React from "react";
import "./Privacy.scss";
import { useTranslation } from "react-i18next";

function Privacy() {
  const { t } = useTranslation();
  let a = (t("privacy.abstract"))
  a = a.replace(/\d+\.([^\n]*)/g, '<h3 class="text-2xl font-extrabold"> - - - $1 - - - </h3>')
  console.log(a)
  return (
    <div> 
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            
          <h2 className="text-4xl font-extrabold dark:text-white">{t("privacy.title")}</h2>

          <br/>
          <p className="abstract mb-4 text-lg font-normal text-gray-500 dark:text-gray-400" dangerouslySetInnerHTML={{
            __html: a
          }}>
          </p>


          

            
           
            
            

          </div>
        </div>
      </main>


    </div>
  );
}

export default Privacy;
