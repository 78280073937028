import { NavLink } from "react-router-dom";
import React from "react";
import { MainMenu } from "../Menu";
import { t } from "i18next";
import i18n from "i18next";
import { langs } from "../../../../langs";

class MobileMenu extends React.Component<{ opened: boolean }> {
  private setLang(lang: string) {
    i18n.changeLanguage(lang);
    (window.location as any) = window.location as any;
  }

  render() {
    return (
      <div
        className="md:hidden"
        id="mobile-menu"
        style={
          this.props.opened
            ? {}
            : {
                display: "none",
              }
        }
      >
        <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
          {MainMenu.map((it) => {
            return (
              <NavLink
                key={it.path}
                to={it.path}
                className={(props) =>
                  props.isActive
                    ? "bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
                    : "text-gray-400 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                }
              >
                {t(t(it.label))}
              </NavLink>
            );
          })}
          <hr/>
          {langs.map((lang) => {
            return (
                <span
                key={lang.lang}
                  className={
                    t(lang.label) === t("lang.current")
                      ? "bg-gray-200 text-gray-500 block px-3 py-2 rounded-md text-base font-medium"
                      : "text-gray-400 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  }
                  role="menuitem"
                  onClick={() => this.setLang(lang.lang)}
                >
                  <div className="inline-flex items-center">
                    {t(lang.label)}
                  </div>
                </span>
            );
          })}
        </div>
      </div>
    );
  }
}

export default MobileMenu;
