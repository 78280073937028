import "./LangPicker.scss";

import React from "react";
import { t } from "i18next";
import i18n from "i18next";
import {
  ChevronDownIcon,
} from "@heroicons/react/24/outline";
import { langs } from "../../../langs";

class LangPicker extends React.Component<
  {},
  {
    visible: boolean;
  }
> {
  constructor(props: any) {
    super(props);
    this.state = { visible: false };
  }



  private toggleMenu() {
    this.setState({ visible: !this.state.visible });
  }

  private setLang(lang: string) {
    i18n.changeLanguage(lang);
    (window.location as any) = window.location as any;
  }

  render() {
    return (
      <div className="hidden md:block">
        <button
          id="dropdownDefaultButton"
          className="font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center      text-sm font-medium text-gray-600 focus:outline-none  rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-200"
          type="button"
          onClick={() => this.toggleMenu()}
        >
          {t("lang.current")}
          <ChevronDownIcon className="w-2.5 h-2.5 ml-2.5" />
        </button>

        <div
          id="dropdown"
          className={
            (this.state.visible ? "block" : "hidden") +
            " z-10 fixed bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
          }
        >
          <ul
            className="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            {langs.map((lang) => {
              return (
                <li key={lang.lang}>
                  <span
                    className="block px-4 cursor-pointer  py-2 hover:bg-gray-100 dark:hover:bg-gray-600 "
                    role="menuitem"
                    onClick={() => this.setLang(lang.lang)}
                  >
                    <div className="inline-flex items-center">
                      {t(lang.label)}
                    </div>
                  </span>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

export default LangPicker;
