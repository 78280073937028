import React from "react";
import "./Features.scss";
import { useTranslation } from "react-i18next";
import appointments from "../../../images/Appointments.png"
import patients from "../../../images/Patients.png"
import dashboard from "../../../images/Dashboard.png"

function Features() {
  const { t } = useTranslation();
  return (
    <div> 
      <main>
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
          <div className="px-4 py-6 sm:px-0">
            
          <h2 className="text-4xl font-extrabold dark:text-white">{t("features.title")}</h2>

          <br/>
          <p className="abstract mb-4 text-lg font-normal text-gray-500 dark:text-gray-400">
          {t("features.abstract")}
          </p>



          <div className="flex flex-col items-center my-10 bg-white border border-gray-200 rounded-lg shadow md:flex-row">
    <img className="object-cover w-full rounded-t-lg h-96  md:rounded-none md:rounded-l-lg" src={dashboard} alt="" />
    <div className="flex flex-col justify-between p-4 leading-normal">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{t("features.dashboard.title")}</h5>
        <p className="mb-3 font-normal text-gray-700">{t("features.dashboard.subtitle")}</p>
    </div>
</div>


<div className="flex flex-col items-center my-10 bg-white border border-gray-200 rounded-lg shadow md:flex-row">
    <img className="object-cover w-full rounded-t-lg h-96  md:rounded-none md:rounded-l-lg" src={patients} alt="" />
    <div className="flex flex-col justify-between p-4 leading-normal">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{t("features.patients.title")}</h5>
        <p className="mb-3 font-normal text-gray-700">{t("features.patients.subtitle")}</p>
    </div>
</div>


<div className="flex flex-col items-center my-10 bg-white border border-gray-200 rounded-lg shadow md:flex-row">
    <img className="object-cover w-full rounded-t-lg h-96  md:rounded-none md:rounded-l-lg" src={appointments} alt="" />
    <div className="flex flex-col justify-between p-4 leading-normal">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{t("features.appointments.title")}</h5>
        <p className="mb-3 font-normal text-gray-700">{t("features.appointments.subtitle")}</p>
    </div>
</div>



    

            
           
            
            

          </div>
        </div>
      </main>


    </div>
  );
}

export default Features;
