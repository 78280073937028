import { Navigate } from "react-router-dom"
import Application from "./components/application/Application"
import Company from "./components/pages/company/Company"
import Features from "./components/pages/features/Features"
import Home from "./components/pages/home/Home"
import LearnMore from "./components/pages/learn-more/LearnMore"
import Privacy from "./components/pages/privacy/Privacy"
 
const routes = [
    {  path: "/", component: (<Home/>) },
    {  path: "/app", component: (<Application/>) },
    {  path: "/company", component: (<Company/>) },
    {  path: "/privacy", component: (<Privacy/>) },
    {  path: "/features", component: (<Features/>) },
    {  path: "/get-started", component: (<Navigate to="/features"/>) },
    {  path: "/learn-more", component: (<Navigate to="/features"/>) },
]

export default routes
