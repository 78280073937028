import "./Home.scss";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import hopital from '../../../images/hospital.png'
import { ArrowLongRightIcon, CameraIcon, ChartBarSquareIcon, CodeBracketIcon } from "@heroicons/react/24/outline";

function Home() {
  const { t } = useTranslation();

  return (
    <div className="Home">
      <section className="bg-center bg-cover bg-no-repeat bg-gray-700 bg-blend-multiply" style={{backgroundImage: "url("  + hopital + ")"}}>
        <div className="px-4 mx-auto max-w-screen-xl text-center py-24 lg:py-56">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-white md:text-5xl lg:text-6xl">
            {t("home.title")}
          </h1>
          <p className="mb-8 text-lg font-normal text-gray-300 lg:text-xl sm:px-16 lg:px-48">
            {t("home.summary")}
          </p>
          <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <NavLink to="/get-started"
              className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
            >
              {t("common.get-started")}
              <ArrowLongRightIcon className="block h-5 w-6" />
            </NavLink>
            <NavLink to="/learn-more"
              className="inline-flex justify-center hover:text-gray-900 items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg border border-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-400"
            >
              {t("common.learn-more")}
            </NavLink>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16">
          <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12 mb-8">
            <span
              className="bg-blue-100 text-blue-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-blue-400 mb-2"
            >
              <CameraIcon className="block h-3 w-6" />
              {t("common.tutorial")}
            </span>
            <h1 className="text-gray-900 dark:text-white text-3xl md:text-5xl font-extrabold mb-2">
            {t("home.tutorial.headline")}
            </h1>
            <p className="text-lg font-normal text-gray-500 dark:text-gray-400 mb-6">
            {t("home.tutorial.summary")}
            </p>
            <NavLink to="/learn-more"
              className="inline-flex justify-center items-center py-2.5 px-5 text-base font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:focus:ring-blue-900"
            >
              {t("common.learn-more")}
            </NavLink>
          </div>
          <div className="grid md:grid-cols-2 gap-8">
            <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12">
              <span
                className="bg-green-100 text-green-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-green-400 mb-2"
              >
               <ChartBarSquareIcon className="block h-3 w-6" />
                {t("common.analytics")}
              </span>
              <h2 className="text-gray-900 dark:text-white text-3xl font-extrabold mb-2">
                {t("home.analytics.headline")}
              </h2>
              <p className="text-lg font-normal text-gray-500 dark:text-gray-400 mb-4">
              {t("home.analytics.summary")}
              </p>
              <NavLink to="/learn-more"
                className="text-blue-600 dark:text-blue-500 hover:underline font-medium text-lg inline-flex items-center"
              >
                {t("common.learn-more")}
                <ArrowLongRightIcon className="block h-4 w-6" />
              </NavLink>
            </div>
            <div className="bg-gray-50 dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg p-8 md:p-12">
              <span
                className="bg-purple-100 text-purple-800 text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded-md dark:bg-gray-700 dark:text-purple-400 mb-2"
              >
                <CodeBracketIcon className="block h-3 w-6" />
                {t("common.features")}
              </span>
              <h2 className="text-gray-900 dark:text-white text-3xl font-extrabold mb-2">
              {t("home.features.headline")}
              </h2>
              <p className="text-lg font-normal text-gray-500 dark:text-gray-400 mb-4">
              {t("home.features.summary")}
              </p>
              <NavLink to="/learn-more"
                className="text-blue-600 dark:text-blue-500 hover:underline font-medium text-lg inline-flex items-center"
              >
                {t("common.learn-more")} 
                <ArrowLongRightIcon className="block h-4 w-6" />
              </NavLink>
            </div>
          </div>
        </div>
        
      </section> 
      
    </div>
  );
}

export default Home;
