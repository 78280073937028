import i18next from "i18next";
import Cache from 'i18next-localstorage-cache';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from "react-i18next";

import fr from "./translations/fr/common";
import en from "./translations/en/common";

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(Cache)
  .use(LanguageDetector)
  .init({
    interpolation: { escapeValue: false },  // React already does escaping    
    fallbackLng: 'en',
    resources: {
        en: {
            translation: en               // 'translation' is our custom namespace
        },
        fr: {
            translation: fr
        },
    },
    detection: {
      order: ["querystring", "lang", "localStorage", "navigator"],
      lookupQuerystring: "lang",
      lookupLocalStorage: "lang",
      caches: ["localStorage"],
    }, 
    react: {
      bindI18nStore: 'languageChanged'
    }   
});

export { i18next };