import './App.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Navbar from './components/elements/navbar/Navbar';
import routes from './router';
import React from 'react';
import Footer from './components/elements/footer/Footer';

class App extends React.Component<{}, { isMobileMenuOpened: boolean }> {

  constructor(props: any) {
    super(props);
    this.state = { isMobileMenuOpened: false };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    this.setState(prevState => ({
      isMobileMenuOpened: !prevState.isMobileMenuOpened
    }));
  }

  render() {


    return this.renderApp()
  }

  renderApp() {
    return (
      <React.Fragment>
        <Router>
          <div className="h-full bg-gray-100" style={{minHeight:'100vh', paddingBottom: "64px"}}>
            <div className="min-h-full">
              <Navbar></Navbar>
              <Routes>
                {
                  routes.map((it) => {
                    return (
                      <Route key={it.path} path={it.path} element={it.component} />
                    )
                  })
                }
              </Routes>
              <Footer></Footer>
            </div>
          </div>
        </Router>
      </React.Fragment>

    );
  }
} 

export default App;

